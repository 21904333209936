import { useEffect, useRef, useState } from 'react';
import { Grid, Divider, Button, Form, Card, CardContent, Search } from 'semantic-ui-react';
import { Checkbox } from 'antd';
import Stepper from './Stepper';
import VopModal from '../components/inputs/VopModal';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getAllUrlParams } from '../../../utils/url';
import NewCompanyAddress from './inputs/NewCompanyAddress';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isEmailValid, renderAddress, renderSeparatedAddress, validatePassword } from '../../../utils/string';
import ContractModal from './inputs/ContractModal';
import moment from 'moment';
import _ from 'lodash';
import { COMPANY_LOOKUP_HOST } from '../../../constants';
import { translations } from '../../../utils/LocalizedStrings';
import PasswordStrengthBar from 'react-password-strength-bar';
import { IsUserAction } from '../../../app/ActionsImpl';

const CheckoutForm = ({
  onSuccess,
  company,
  representation,
  zivnostiPrice,
  clearSteps,
  documents,
  zivnosti,
  representationPrice,
  freeCraftPrice,
  skillCraftPrice,
  boundCraftPrice,
  licensedCraftPrice,
  courtFee,
  values,
  token,
  documentsMap,
  allowInvoiceOtherCompany,
  user,
  userProfileData,
  userOrdersCount,
  returnLessons,
  templateToken,
  publicVops,
  language,
  settings,
  setIsPartnerRegistration,
  handleLoginForExistingUser,
  isUserAction
}) => {
  const editorState = useSelector((state) => state.editor.editorState);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});
  const [isFilled, setIsFilled] = useState(true);

  const [price, setPrice] = useState(0);
  const [priceToRefund, setPriceToRefund] = useState(0);
  const [count, setCount] = useState(0);
  const [selectedSteps] = useState(getAllUrlParams().steps?.split(','));
  const [validEmail, validateEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [validationPassMessages, setValidationPassMessages] = useState('');
  const [checkedConfirmCheckbox, setCheckedConfirmCheckbox] = useState(false);
  const [vopModalVisible, setVopModalVisible] = useState(false);
  const [contractModalVisible, setContractModalVisible] = useState(false);
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);
  const [companyName, setCompanyName] = useState(true);

  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({});
  const [results, setResults] = useState([]);
  const [shouldLogin, setShouldLogin] = useState(false);
  const promises = useRef();

  const handleField = (e) => {
    if (e.persist) {
      e.persist();
    }
    setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (!allowInvoiceOtherCompany) {
      setIsFilled(true)
    }
  }, [allowInvoiceOtherCompany])

  useEffect(() => {
    setShouldLogin(false)
  }, [formData.kontakt_email])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isUser = await isUserAction(formData.kontakt_email)
    if (!user && isUser.response) {
      setShouldLogin(true)
      return
    }

    const orderKey = `${formData.kontakt_email}_${Date.now()}`;
    let finalPrice = parseFloat(price);
    if (user?.attributes && user?.attributes["custom:customer_role"] && userProfileData?.partnerData) {
      finalPrice = parseFloat(price) - (parseFloat(price) * (Number(settings.settings.partnerDiscount) / 100)).toFixed(2)
    }
    finalPrice = (Math.round(finalPrice * 100) / 100).toFixed(2);
    console.log(finalPrice)
    let finalZivnostiPrice = parseFloat(zivnostiPrice);
    let finalCourtFee = representation && courtFee ? parseFloat(courtFee) : 0;
    let finalRepresentationPrice = representation
      ? parseFloat(representationPrice) * 1.23
      : 0;
    onSuccess({
      ...formData,
      orderKey,
      price:
        finalPrice * 1.23 +
        finalZivnostiPrice +
        finalCourtFee +
        finalRepresentationPrice,
      priceWithoutVat:
        finalPrice + (representation ? parseFloat(representationPrice) : 0),
      zivnostiPrice: finalZivnostiPrice,
      priceToRefund: priceToRefund,
      zivnosti,
      templateToken: templateToken,
      companyName,
      representationPrice: parseFloat(representationPrice),
      courtFee: finalCourtFee,
      representation: !!representation ? 'true' : 'false',
      campaignsAcceppted: Date.now()
    });
  };

  useEffect(() => {
    if (user?.attributes && user?.attributes["custom:customer_role"] && userProfileData?.partnerData) {

      let icdph = userProfileData.partnerData.vat
      let dic = userProfileData.partnerData.tin
      setFormData((old) => ({ ...old, spolocnost: userProfileData.partnerData.business_name, companyName: userProfileData.partnerData.business_name, dic, icdph }));
      setCompanyName(userProfileData.partnerData.business_name)
      setFormData((old) => ({
        ...old,
        adresa: renderSeparatedAddress(userProfileData.partnerData.address),
      }));
      setFormData((old) => ({ ...old, ico: userProfileData.partnerData.cin }));
    } else if (isFilled) {
      if (company.ico) {
        if (company.meno) {
          let icdph = company.VAT?.IC_DPH
          let dic = company.TIN?.DIC
          setFormData((old) => ({ ...old, spolocnost: company.meno.value, companyName: company.meno.value, dic, icdph }));
          setCompanyName(company.meno.value)
        }
        setFormData((old) => ({
          ...old,
          adresa: renderAddress(company.adresa),
        }));
        setFormData((old) => ({ ...old, ico: company.ico.value }));
        // setSearchString(company.name)
      } else if (values.companyAddress) {
        setFormData((old) => ({ ...old, spolocnost: values.companyName }));
        setCompanyName(values.companyName)
        // setSearchString(values.companyName)
        setFormData((old) => ({
          ...old,
          adresa: NewCompanyAddress.HTMLRenderer(values),
        }));
      } else {
        setFormData((old) => ({ ...old, spolocnost: values.companyName }));
        setCompanyName(values.companyName)
        // setSearchString(values.companyName)
      }
    } else {
      setFormData((old) => ({ ...old, spolocnost: '' }));
      setFormData((old) => ({
        ...old,
        adresa: '',
      }));
      setSearchString("")
      setFormData((old) => ({ ...old, ico: '' }));
      setFormData((old) => ({ ...old, dic: '' }));
      setFormData((old) => ({ ...old, icdph: '' }));
    }
  }, [isFilled]);

  useEffect(() => {
    let priceParsed = JSON.parse(JSON.stringify(editorState.metadata.prices));
    let priceToRefund = 0
    priceParsed =
      priceParsed
        .sort((a, b) =>
          parseInt(a.count) > parseInt(b.count)
            ? 1
            : parseInt(b.count) > parseInt(a.count)
              ? -1
              : 0,
        )
        .find((price) => parseInt(price.count) >= clearSteps.filter(step => !documentsMap[step].value.price).length) ||
      editorState.metadata.prices[editorState.metadata.prices.length - 1];
    for (let step of clearSteps) {
      if (documentsMap[step] && documentsMap[step].value.price) {
        priceParsed.price = parseFloat(priceParsed.price) + parseFloat(documentsMap[step].value.price)
        if ((step === '1626083313170' || step === '1634731083885') && userOrdersCount > 0 && user) {
          priceToRefund += (parseFloat(documentsMap[step].value.price) / 2)
        }
      }
    }
    setPrice(parseFloat(priceParsed.price).toFixed(2));
    setPriceToRefund(priceToRefund)
    setCount(parseInt(priceParsed.count));
  }, [representation, userOrdersCount, documents, zivnosti]);

  let stepsArray = [
    translations.artifacts.StepperBox.firstStep,
    translations.artifacts.StepperBox.secondStep,
    translations.artifacts.StepperBox.thirdStep,
    translations.artifacts.StepperBox.fourthStep,
  ];

  useEffect(() => {
    if (user && user.attributes) {
      handleField({ target: { name: "kontakt_meno", value: `${user.attributes.given_name} ${user.attributes.family_name}` } })
      handleField({ target: { name: "kontakt_email", value: user.attributes.email } })
      if (user.attributes.phone_number) {
        handleField({ target: { name: "kontakt_phone", value: user.attributes.phone_number } })
      }
    }
  }, [user])

  useEffect(() => {
    if (formData.kontakt_phone) {
      setPhoneNumberValid(isValidPhoneNumber(formData.kontakt_phone))
    } else {
      setPhoneNumberValid(true)
    }
    setValidPassword(doValidatePasswords(formData.pass1, formData.pass2))
  }, [formData])

  const allLessonsAccepted = () => {
    let lessonsCount = Object.entries(documentsMap).filter(
      ([key, document]) =>
        document.value.requireLesson
    ).length
    let acceptedLessonsCount = values.lessons ? Object.entries(values.lessons).filter(([key, lesson]) => lesson.accepted === true).length : 0
    return lessonsCount === acceptedLessonsCount
  }

  const acceptAllLessons = () => {
    let lessonsToAccept = Object.entries(documentsMap).filter(
      ([key, document]) =>
        document.value.requireLesson
    )
    let newValues = {};

    for (let doc of lessonsToAccept) {
      newValues[doc[1].id.split("-")[0]] = {
        accepted: true,
        at: moment().format()
      }
    }
    returnLessons(newValues)
  }

  const promiseCompanyLookup = (token, isCreateMode, type = 'sro') => {
    const controller = new AbortController();
    const signal = controller.signal;
    let cPromise = new Promise((resolve, reject) => {
      fetch(
        `${COMPANY_LOOKUP_HOST}/company/lookup-by-name${isCreateMode ? '-available' : ''
        }`,
        {
          body: JSON.stringify({ token, oddiel: type }),
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        },
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res.data);
        });
    });
    cPromise.cancel = () => controller.abort();
    return cPromise;
  };

  const handleSearchChange = async (e, data, isCreateMode) => {
    // setResults([]);
    // setFreeCompanyName([]);
    setLoading(true);
    // setNewCompanyName(data.value);
    setSelectedCompany({});
    promises.current = promises.current || [];
    promises.current.forEach((p) => p.cancel());
    promises.current = [];
    const responseData = promiseCompanyLookup(data.value, isCreateMode, 'sro');
    promises.current.push(responseData);
    responseData
      .then((responseData) => {
        if (responseData) {
          setResults(
            responseData.map((item) => ({
              title: item.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value,
              description: `IČO: ${item.identifiers.filter(i => moment(i.validFrom).isSameOrBefore(moment()) && !i.validTo)[0].value}`,
              full_data: item,
            })),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const debounceHandleSearchChange = useRef(
    _.debounce(handleSearchChange, 500, {
      loading: true,
    }),
  );

  const onSearchChange = (e, data) => {
    setLoading(true);
    let newSearch = data.value;
    if (/^[0-9]+\s+$/.test(newSearch)) {
      newSearch = newSearch.replace(/\s/g, '');
    }

    setSearchString(newSearch);
    debounceHandleSearchChange.current(e, data, false);
  };

  const doValidatePasswords = (pass1, pass2) => {

    let messages = '';

    if (user === undefined) {
      if (!validatePassword(pass1)) {
        messages += translations.artifacts.CheckouFormBox.weakPassword
      }
    }

    if (messages.length === 0) {
      return true
    } else {
      setValidationPassMessages(messages)
      return false
    }

  }

  useEffect(() => {
    if (!isFilled && selectedCompany && selectedCompany.fullNames && selectedCompany.identifiers) {
      setFormData((old) => ({ ...old, spolocnost: selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value }));
      setSearchString(selectedCompany.fullNames.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value);
      setFormData((old) => ({ ...old, ico: selectedCompany.identifiers.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0].value }));
      if (selectedCompany && selectedCompany.addresses) {
        setFormData((old) => ({ ...old, adresa: renderAddress(selectedCompany.addresses.filter(n => moment(n.validFrom).isSameOrBefore(moment()) && !n.validTo)[0]) }));
      }
    }
  }, [selectedCompany])

  function addTextToElementNoResults() {
    const newDiv = document.createElement("div");
    newDiv.classList.add("noResultsMessage");
    const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.noResult);
    newDiv.appendChild(newContent);
    const el = document.querySelector('.search > div.results.transition.visible > div.empty.message')
    if (el) {
      el.appendChild(newDiv);
    }
  }

  function addTextToElementSearchingResults() {
    const newDiv = document.createElement("div");
    newDiv.classList.add("resultsParagraph");
    const newContent = document.createTextNode(translations.artifacts.NewOwnerComponent.specifySearchingName);
    newDiv.appendChild(newContent);
    const el = document.querySelector('.orderUiForm .ui.search .results')
    if (el) {
      el.appendChild(newDiv);
    }
  }

  useEffect(() => {
    addTextToElementNoResults()
    addTextToElementSearchingResults()
  }, [results]);

  return (
    <div className="orderForm checkoutFormModal">
      <Stepper steps={stepsArray} currentStepNumber={2} />
      <Grid container centered style={{ marginTop: 10 }}>
        <Grid.Column mobile={15} widescreen={15}>
          <h3 className="orderHeading">{ }</h3>
          <Card className="orderCard">
            <CardContent>
              <Form className="orderUiForm">
                <Form.Field>
                  {allowInvoiceOtherCompany &&
                    <Checkbox
                      checked={!isFilled}
                      className="orderFormCheckbox differentCompanyCheckbox"
                      onClick={() => setIsFilled(!isFilled)}>
                      {translations.artifacts.CheckouFormBox.makeInvoice}
                    </Checkbox>
                  }
                  {isFilled &&
                    <Form.Input
                      disabled={isFilled}
                      className="orderRow"
                      name="spolocnost"
                      label={translations.artifacts.CheckouFormBox.company}
                      value={formData.spolocnost}
                      onChange={handleField}
                      required
                    />
                  }
                  {!isFilled &&
                    <>
                      <span className='searchCompanyNameLabel'>Názov spoločnosti</span>
                      <Search
                        disabled={isFilled}
                        loading={loading}
                        onResultSelect={(e, data) => {
                          setSelectedCompany(data.result.full_data);
                        }}
                        onSearchChange={onSearchChange}
                        results={loading ? [] : results}
                        placeholder={isFilled ? formData.spolocnost : ''}
                        value={searchString}
                        size={'big'}
                        className="orderRow"
                      />
                    </>}
                  <Form.Input
                    className="orderRow"
                    label={translations.artifacts.CheckouFormBox.companyAdress}
                    name="adresa"
                    value={formData.adresa}
                    onChange={handleField}
                    required
                  />
                  {company.ico &&
                    <Form.Field className="contactField">
                      <Form.Input
                        disabled={isFilled}
                        className="invoiceNumber"
                        label="IČO"
                        name="ico"
                        value={formData.ico}
                        onChange={handleField}
                      />
                      <Form.Input
                        className="invoiceNumber"
                        label={translations.artifacts.CheckouFormBox.dic}
                        name="dic"
                        value={formData.dic}
                        onChange={handleField}
                      />
                      <Form.Input
                        className="invoiceNumber"
                        label={translations.artifacts.CheckouFormBox.icdph}
                        name="icdph"
                        value={formData.icdph}
                        onChange={handleField}
                      />
                    </Form.Field>
                  }
                </Form.Field>
              </Form>
            </CardContent>
          </Card>
          <h3 className="orderHeading">{translations.artifacts.CheckouFormBox.contactData}</h3>
          <Card className="orderCard">
            <CardContent>
              <Form className="orderUiForm">
                <Form.Field className="contactField nameSurmanePhone">
                  <Form.Input
                    label={translations.artifacts.CheckouFormBox.nameSurname}
                    name="kontakt_meno"
                    value={formData.kontakt_meno}
                    onChange={handleField}
                    required
                  />
                  <Form.Input
                    label="E-mail"
                    id={'emailInputCheckoutForm'}
                    name="kontakt_email"
                    className={validEmail ? '' : "invalid"}
                    value={formData.kontakt_email}
                    onChange={(e) => {
                      handleField(e);
                      validateEmail(isEmailValid(e.target.value));
                    }}
                    error={
                      validEmail
                        ? false
                        : {
                          content: translations.artifacts.CheckouFormBox.enterValidMail,
                        }
                    }
                    required
                  />
                  <div className="field">
                    <div className={formData.kontakt_phone &&
                      !isValidPhoneNumber(formData.kontakt_phone) ? 'phoneInputLabel invalid' : 'phoneInputLabel'}>{translations.artifacts.CheckouFormBox.phone}</div>
                    <PhoneInput
                      id={'phoneNumberInputCheckoutForm'}
                      name="kontakt_phone"
                      value={formData.kontakt_phone}
                      defaultCountry="SK"
                      withCountryCallingCode={true}
                      useNationalFormatForDefaultCountryValue={true}
                      className={(isPhoneNumberValid) ? 'valid' : 'invalid'}
                      onChange={(value) => {
                        setFormData((old) =>
                          JSON.parse(
                            JSON.stringify({ ...old, ['kontakt_phone']: value }),
                          ),
                        );
                      }}
                    />
                    {formData.kontakt_phone &&
                      !isValidPhoneNumber(formData.kontakt_phone) &&
                      <div className='ui pointing above prompt label'>{translations.artifacts.CheckouFormBox.enterValidPhone}</div>
                    }
                  </div>
                </Form.Field>
                {user === undefined &&
                  <>
                    <Form.Field className="contactField nameSurmanePhone" id="passwordStrengthBarContainer">
                      {formData.pass1 && formData.pass1.length > 0 &&
                        < PasswordStrengthBar scoreWords={[
                          translations.artifacts.UserRegistrationBox.passwordTooWeak,
                          translations.artifacts.UserRegistrationBox.passwordWeak,
                          translations.artifacts.UserRegistrationBox.passwordGood,
                          translations.artifacts.UserRegistrationBox.passwordStrong,
                          translations.artifacts.UserRegistrationBox.passwordTooStrong
                        ]}
                          shortScoreWord={translations.artifacts.UserRegistrationBox.passwordShort}
                          className={user ? 'passwordStrengthBar' : 'passwordStrengthBar noUser'} password={formData.pass1} />
                      }
                      <Form.Input
                        label={translations.artifacts.CheckouFormBox.enterPassword}
                        name="pass1"
                        value={formData.pass1}
                        onChange={(e) => {
                          handleField(e);
                        }}
                        error={
                          validPassword
                            ? false
                            : {
                              content: validationPassMessages,
                            }
                        }
                        required
                        type='password'
                      />
                      <Form.Input
                        className='repeatPassword'
                        label={translations.artifacts.PasswordResetFormBox.repeatPassword}
                        name="pass2"
                        value={formData.pass2}
                        onChange={handleField}
                        required
                        type='password'
                        error={
                          formData.pass1 === formData.pass2
                            ? false
                            : {
                              content: translations.artifacts.PasswordResetFormBox.passwordArentSame,
                            }
                        }
                      />
                    </Form.Field>
                  </>
                }
              </Form>
              <p className='checkoutFormPartnerMessage'>V prípade, že ste advokát, notár alebo účtovník a chcete sa registrovať ako partner, uložíme Vám rozpracovanú objednávku a registrujte sa kliknutím
                <span onClick={() => setIsPartnerRegistration(true)}> sem</span>. </p>
            </CardContent>
          </Card>
          <h3 className="orderHeading">Rekapitulácia objednávky</h3>
          <Card className="orderCard">
            <CardContent>
              <Form onSubmit={handleSubmit} className="orderUiForm">
                <Form.Field>
                  <div className="orderBasketItem">
                    <b>{translations.artifacts.CheckouFormBox.docs}</b>
                  </div>
                  {clearSteps !== undefined &&
                    clearSteps.length > 0 &&
                    clearSteps.map((step, index) => {
                      return (
                        <div className="orderBasketItem">
                          <span>
                            {documents.filter(
                              (step2) => step2.id.split('-')[0] === step,
                            )[0]
                              ? documents.filter(
                                (step2) => step2.id.split('-')[0] === step,
                              )[0].name.split('-')[0]
                              : ''}
                          </span>
                        </div>
                      );
                    })}
                  <div className="orderBasketItem">
                    <span>{translations.artifacts.CheckouFormBox.moenyFor}</span>
                    <span style={{ whiteSpace: 'pre' }}>
                      {parseFloat(price).toLocaleString('sk-SK', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}{' '}
                      €
                    </span>
                  </div>
                  {(user?.attributes && user?.attributes["custom:customer_role"] && userProfileData?.partnerData) &&
                    <>
                      <div className="orderBasketItem">
                        <span>{translations.artifacts.CheckouFormBox.discount}</span>
                        <span style={{ whiteSpace: 'pre' }}>
                          - {(parseFloat(price) * (Number(settings.settings.partnerDiscount) / 100)).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}{' '}
                          €
                        </span>
                      </div>
                    </>
                  }
                  <Divider />
                  {representation && (!user || !user.attributes || !user.attributes["custom:customer_role"]) && (
                    <>
                      <div className="orderBasketItem">
                        <b>{translations.artifacts.CheckouFormBox.representing}</b>
                      </div>
                      <div className="orderBasketItem">
                        <span>{translations.artifacts.CheckouFormBox.moneyForRepresenting}</span>
                        <span style={{ whiteSpace: 'pre' }}>
                          {parseFloat(representationPrice).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}{' '}
                          €
                        </span>
                      </div>
                    </>
                  )}
                  <Divider />
                  <div className="orderBasketItem">
                    <span>{translations.artifacts.CheckouFormBox.withoutWat}</span>
                    {(user?.attributes && user?.attributes["custom:customer_role"] && userProfileData?.partnerData) ?
                      <span style={{ whiteSpace: 'pre' }}>
                        {((
                          parseFloat(price) +
                          parseFloat(representation ? representationPrice : 0)) - (parseFloat(price) * (Number(settings.settings.partnerDiscount) / 100))
                        ).toLocaleString('sk-SK', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}{' '}
                        €
                      </span>
                      :
                      <span style={{ whiteSpace: 'pre' }}>
                        {((
                          parseFloat(price) +
                          parseFloat(representation ? representationPrice : 0))
                        ).toLocaleString('sk-SK', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}{' '}
                        €
                      </span>
                    }
                  </div>
                  <div className="orderBasketItem">
                    <span>{translations.artifacts.CheckouFormBox.with20Wat}</span>
                    {(user?.attributes && user?.attributes["custom:customer_role"] && userProfileData?.partnerData) ?
                      <span style={{ whiteSpace: 'pre' }}>
                        {parseFloat(
                          (price - (parseFloat(price) * (Number(settings.settings.partnerDiscount) / 100))) * 0.23 +
                          parseFloat(representation ? representationPrice * 0.23 : 0),
                        ).toLocaleString('sk-SK', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}{' '}
                        €
                      </span>
                      :
                      <span style={{ whiteSpace: 'pre' }}>
                        {parseFloat(
                          price * 0.23 +
                          parseFloat(representation ? representationPrice * 0.23 : 0),
                        ).toLocaleString('sk-SK', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}{' '}
                        €
                      </span>
                    }
                  </div>
                  {representation &&
                    ((courtFee && courtFee !== 0 && !isNaN(courtFee)) ||
                      zivnosti.length > 0) && (
                      <>
                        <Divider />
                        <div className="orderBasketItem">
                          <b>{translations.artifacts.CheckouFormBox.fees}</b>
                        </div>
                      </>
                    )}
                  {representation && courtFee && courtFee !== 0 && !isNaN(courtFee) ? (
                    <div className="orderBasketItem">
                      <span>{translations.artifacts.CheckouFormBox.courtFee}</span>
                      <span style={{ whiteSpace: 'pre' }}>
                        {parseFloat(courtFee).toLocaleString('sk-SK', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}{' '}
                        €
                      </span>
                    </div>
                  ) : <></>}
                  {zivnosti &&
                    zivnosti.length > 0 &&
                    zivnosti.map((zivnost) => {
                      return (
                        <div className="orderBasketItem">
                          <span>{zivnost.name}</span>
                          <span style={{ whiteSpace: 'pre' }}>
                            {zivnost.type === 'VOLNE'
                              ? parseFloat(freeCraftPrice).toLocaleString('sk-SK', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                              : zivnost.type === 'REMESELNE'
                                ? parseFloat(skillCraftPrice).toLocaleString('sk-SK', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })
                                : zivnost.type === 'LICENSED'
                                  ? parseFloat(licensedCraftPrice).toLocaleString(
                                    'sk-SK',
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2
                                    },
                                  )
                                  : parseFloat(boundCraftPrice).toLocaleString('sk-SK', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                  })}{' '}
                            €
                          </span>
                        </div>
                      );
                    })}
                  <Divider />
                  {priceToRefund > 0 &&
                    <>
                      <div className="orderBasketResult dobropisPrice">
                        <span>{translations.artifacts.CheckouFormBox.sendingFee}</span>
                        <span style={{ whiteSpace: 'pre' }}>
                          {(priceToRefund * 1.23).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}{' '}
                          €
                        </span>
                      </div>
                      <Divider />
                    </>
                  }
                  {(user?.attributes && user?.attributes["custom:customer_role"] && userProfileData?.partnerData) ?
                    <>
                      <div className="orderBasketResult">
                        <span>{translations.artifacts.CheckouFormBox.finalAmount}</span>
                        <span style={{ whiteSpace: 'pre' }}>
                          {(((
                            (parseFloat(price) - (parseFloat(price) * (Number(settings.settings.partnerDiscount) / 100))) +
                            parseFloat((price - (parseFloat(price) * (Number(settings.settings.partnerDiscount) / 100))) * 0.23) +
                            parseFloat(zivnostiPrice) +
                            (representation && courtFee ? parseFloat(courtFee) : 0) +
                            parseFloat(representation ? representationPrice * 1.2 : 0)
                          ) - (priceToRefund * 1.23))).toLocaleString('sk-SK', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}{' '}
                          €
                        </span>
                      </div>
                    </>
                    :
                    <div className="orderBasketResult">
                      <span>{translations.artifacts.CheckouFormBox.finalAmount}</span>
                      <span style={{ whiteSpace: 'pre' }}>
                        {((
                          parseFloat(price) +
                          parseFloat(price * 0.23) +
                          parseFloat(zivnostiPrice) +
                          (representation && courtFee ? parseFloat(courtFee) : 0) +
                          parseFloat(representation ? representationPrice * 1.23 : 0)
                        ) - (priceToRefund * 1.23)).toLocaleString('sk-SK', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}{' '}
                        €
                      </span>
                    </div>
                  }
                  <Divider />
                  <div className="orderCheckbox">
                    <Checkbox
                      style={{ margin: '15px 20px' }}
                      onChange={acceptAllLessons}
                      className="orderFormCheckbox acceptGuidanceCheckbox"
                      disabled={allLessonsAccepted()}
                      checked={allLessonsAccepted()}
                    ></Checkbox>
                    <label>
                      {translations.artifacts.CheckouFormBox.acceptTips}
                    </label>
                  </div>
                  <div className="orderCheckbox">
                    <VopModal
                      publicVops={publicVops}
                      language={language}
                      visible={vopModalVisible}
                      handleClose={() => setVopModalVisible(false)}
                    />
                    <ContractModal
                      documents={documents}
                      visible={contractModalVisible}
                      handleClose={() => setContractModalVisible(false)}
                      values={values}
                      user={user}
                      userProfileData={userProfileData}
                    />
                    <Checkbox
                      style={{ margin: '15px 20px' }}
                      className="orderFormCheckbox"
                      checked={checkedConfirmCheckbox}
                      onChange={(e) =>
                        setCheckedConfirmCheckbox(e.target.checked)
                      }></Checkbox>
                    <label>
                      {translations.artifacts.CheckouFormBox.iaccept}{' '}
                      <span
                        className="vopCheckbox"
                        onClick={() => setVopModalVisible(true)}>
                        {translations.artifacts.CheckouFormBox.universalRules}
                      </span>
                      <span>
                        ,&nbsp;
                      </span>
                      <span
                        className="vopCheckbox"
                        onClick={() => setContractModalVisible(true)}>
                        {translations.artifacts.CheckouFormBox.aboutContract}
                      </span>
                      <span>
                        &nbsp;a&nbsp;
                      </span>
                      {translations.artifacts.CheckouFormBox.campaignAgreement}
                    </label>
                  </div>

                </Form.Field>

                {shouldLogin && !user &&
                  <Button id="shouldLoginButton" onClick={() => handleLoginForExistingUser()}>Účet s uvedeným emailom je už vytvorený, prosím prihláste sa</Button>
                }

                <Button
                  className="ui primary big button confirmOrderButton"
                  type="submit"
                  disabled={
                    validPassword === false ||
                    checkedConfirmCheckbox === false ||
                    !formData.kontakt_meno ||
                    formData.kontakt_meno === '' ||
                    formData.adresa === '' ||
                    !formData.adresa ||
                    formData.spolocnost === '' ||
                    !formData.spolocnost ||
                    !isEmailValid(formData.kontakt_email) ||
                    (formData.kontakt_phone &&
                      !isValidPhoneNumber(formData.kontakt_phone)) ||
                    !allLessonsAccepted()
                    ||
                    (!isFilled && (!formData.ico || formData.ico === ''))
                  }>
                  {translations.artifacts.CheckouFormBox.finishOrder}
                </Button>
              </Form>
            </CardContent>
          </Card>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ appState }) => ({
  company: appState.company,
  allowInvoiceOtherCompany: appState.allowInvoiceOtherCompany,
  user: appState.cognitoUser,
  userProfileData: appState.userProfileData,
  userOrdersCount: appState.userOrdersAmount,
  templateToken: appState.token,
  publicVops: appState.publicVops,
  language: appState.language,
  settings: appState.settings
});

const mapDispatchToProps = {
  isUserAction: IsUserAction
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm);
